import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Contactus from '../../components/Contactus'
import RagDilImage1 from '../../assets/images/kitten1.png'
import RagDilImage2 from '../../assets/images/kitten2.png'

const KittenForAdoption = () => {
  return (
    <>
      <section className="section-container kitten-adoption">
        <Container>
          <Row>
            <Col md="7">
              <div className="body-content ms-0 me-md-5 kitten-container">
                <h2>Kittens for adoption</h2>
                <div className="content-div">
                  <p>
                    If you’re interested in adopting a Sweet Afton Ragdoll, a $100 deposit will hold
                    a spot for you on our waiting list, which determines the order of choosing from
                    available kittens and will be deducted from the adoption fee. A deposit is the
                    only way to be placed on our waiting list. We will email our deposit agreement
                    to you. Most of our kittens are typically claimed with a deposit before they are
                    born. All kittens are ready to move in with their new families 12 weeks after
                    birth. Our fee includes the first two rounds of vet checks and vaccines, the
                    spay/neuter, and a two-year genetic health guarantee. Males and females are
                    $1250. We are an HCM-free cattery, and all parents are registered with The
                    International Cat Association (TICA).
                  </p>
                  <p>
                    Kitten availability is constantly changing because of breeding cycles and our
                    everchanging waiting list. Although there is always a running waiting list, that
                    doesn’t necessarily mean you will need to wait a long time for a kitten. Some
                    folks on the list are waiting for a specific kitten or have a specific time
                    frame in mind. There are also occasional last-minute cancellations that occur
                    due to different family situations that may make a kitten available to you.
                  </p>
                  <p>
                    When your turn comes to choose, we will email photos of current litters and
                    individual kittens to you as they grow. After the kittens’ 8-week vet check,
                    “Kitten Pickin’” begins! We encourage each family/individual to visit us at our
                    home so we can all meet each other, you can meet the parents and play with the
                    kittens, and have all your questions answered. We have about 15 years of
                    additional candid Ragdoll photos we can email to you for your viewing enjoyment,
                    if you’re interested!
                  </p>
                  <p>
                    Adding a Sweet Afton Ragdoll to your family is just the beginning of a wonderful
                    adventure! Thanks for checking us out!
                  </p>
                  <p>Dan and Lynne</p>
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="img-container about-ragdol-overlay-images">
                <img src={RagDilImage1} alt="" className="w-100" />
                <img src={RagDilImage2} alt="" className="w-100" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Contactus />
    </>
  )
}

export default KittenForAdoption
