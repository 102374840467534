import React from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import ContactImg from '../../assets/images/ill_contact.png'

export default function Contactus() {
  return (
    <section className="section-container contact-container">
      <Container>
        <Row>
          <Col md="7">
            <div className="contact-form body-content">
              <h2 className="text-white">Contact us</h2>
              <p className="text-white my-4">
                Please contact us with questions and for information about upcoming litters!
              </p>
              <p className="text-white my-0 text-with-line">Dan and Lynne Woodhull</p>
              <p className="text-white mt-2 mb-2 medium-text">
                Phone number: <span>(719) 505-6582</span>
              </p>
              <p className="text-white mt-0 medium-text">
                E-mail: <span>info@sweetaftoncats.com</span>
              </p>
              <p className="text-white my-4">
                We could never adequately express our deep gratitude to our mentor and friend, the
                beautiful, brilliant, and very patient Scottie Cone, owner of Rags2Riches Ragdolls,
                currently of Biloxi, Mississippi.
              </p>
              <div className="d-flex">
                <Input type="number" placeholder="phone number" />
                <Button>Send</Button>
              </div>
            </div>
          </Col>
          <Col md="5">
            <div className="img-container">
              <img src={ContactImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
