import { Col, Row,Container, FormGroup, Label, Input, Button } from "reactstrap"
import illContactUs from '../../assets/images/ill_contact us.png'
import { useState } from "react"
import { toast } from "react-toastify";
import validator from 'validator';

const ContactUsPage = () => {
    const [formValues, setFormValues] = useState({ email: '', phoneNumber: '', message: '' });

    const validateForm = () => {
        if(!validator.isEmail(formValues.email)) {
            toast.error("Please enter valid email address")
            return false;
        } else if(!validator.isMobilePhone(formValues.phoneNumber)) {
            toast.error("Please enter valid phone number")
            return false;
        } else {
            return true;
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        if(validateForm()) {
            console.log("done");
        }
    }

    return (
        <>
            <section className="section-container">
                <Container>
                    <Row className="mb-5">
                        <Col md={6} className="pe-md-4">
                            <div className="contact-image h-100 pe-md-3">
                                <img className="img-fluid h-100" alt="illContactUs-img" src={illContactUs} />
                            </div>    
                        </Col>
                        <Col md={6} className="ps-md-4">
                            <div className="body-content ms-0 ps-md-3">
                                <h2>Contact Us</h2>
                                <div className="contact-us-form mt-4 pt-1">
                                    <form onSubmit={submitForm}>
                                        <FormGroup>
                                            <Label for="email">Email address<span className="astrick-symbol">*</span></Label>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Enter email address" onChange={(e) => setFormValues({...formValues, email: e.target.value})} required />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="phonenumber">Phone number<span className="astrick-symbol">*</span></Label>
                                            <Input 
                                                type="text" 
                                                name="phoneNumber" 
                                                id="phone-number" 
                                                placeholder="Enter phone number" 
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => setFormValues({...formValues, phoneNumber: e.target.value})} 
                                                required 
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Message<span className="astrick-symbol">*</span></Label>
                                            <Input type="textarea" name="message" rows="2" id="message" placeholder="Enter message" onChange={(e) => setFormValues({...formValues, message: e.target.value})} required />
                                        </FormGroup>
                                        <Button disabled={formValues.email === '' || formValues.message === '' || formValues.message === ''} className="send-btn mt-3">Send</Button>
                                    </form>    
                                </div>
                            </div>    
                        </Col>
                    </Row>
                </Container>        
            </section>
        </>
    )
}

export default ContactUsPage