import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Contactus from '../../components/Contactus'

const ConcerningRagdolls = () => {
  return (
    <>
      <section className="section-container">
        <Container>
          <Row>
            <Col md={{ size: 7, offset: 3 }}>
              <div className="body-content ms-0">
                <h2>Concerning Ragdolls</h2>
                <div className="content-div">
                  <p>
                    The Ragdoll cat breed has a very interesting and mysterious history! There are a
                    variety of stories of how Ragdolls came into existence; rather than post pages
                    of info here, you may want to do a little research online if you’re interested
                    in learning more.
                  </p>
                  <p>
                    Ragdoll cats have rabbit-soft medium to long fur length and expressive large
                    blue eyes. They love to be brushed and have one-on-one time with their humans,
                    even with multiple cats in the home. Ragdolls are graceful, gentle, and
                    affectionate creatures, although they each have their own unique personality.
                    They are aptly named, as they tend to go limp like a ragdoll toy when they are
                    picked up. This feline phenomenon is known as “the Flop Factor.” As kittens,
                    Ragdolls are adorably cute and very playful and cuddly. When born, Ragdolls are
                    pure white, and their colors develop as they mature—reaching their full coloring
                    and maximum weight at three to four years of age. Our females reach an
                    approximate weight of eight to ten pounds, and males can weigh up to fifteen
                    pounds.
                  </p>
                  <h5 className="d-flex align-items-center">
                    <span>&ldquo;</span> The smallest feline is a masterpiece.
                  </h5>
                  <h6>Leonardo da Vinci</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Contactus />
      <section className="section-container">
        <Container>
          <Row>
            <Col md={{ size: 7, offset: 3 }}>
              <div className="body-content ms-0">
                <h2>Are Ragdolls suitable pets for you?</h2>
                <div className="content-div">
                  <p>
                  Carefully consider your and your family’s makeup and schedule before choosing to bring a Ragdoll cat—or any pet—into your household. Although Ragdolls can be rather “dog-like” in behavior, if you are looking for a pet that will obey commands, a cat is not for you. Cat owners enjoy the independent spirit of their pets; it is an inborn trait.
                  </p>
                  <p>
                  Children should be supervised as they are taught to handle and care for their new friend with gentleness, kindness, and patience. Ragdoll cats are known especially for their quiet, loving nature, but that does not mean that they do not feel pain just like any other person or animal. Rough handling and other inappropriate treatment can cause any good-natured, outgoing cat to become skittish and want to hide from rather than run to greet their people, or they may develop other unwanted behaviors.
                  </p>
                  <h5 className="d-flex align-items-center">
                    <span>&ldquo;</span> A kitten is in the animal world what a rosebud is in the garden.
                  </h5>
                  <h6>Robert Southey</h6>
                  <p>Ragdolls could become depressed and even ill if left alone regularly for long periods of time. If you have busy work and/or school schedules in your home when the cat would be left alone much of the day, it would be kind to purchase two Ragdolls, or you may consider adopting a companion pet from a local animal shelter. Our cats do cry out for company when they are alone and are visibly and audibly relieved when they “find” us again. They prefer being near their people, but they also certainly enjoy romping and napping with the other Raggies and “their” doggie!</p>
                  <h5 className="d-flex align-items-center">
                    <span>&ldquo;</span> One cat just leads to another.
                  </h5>
                  <h6>Ernest Hemingway</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ConcerningRagdolls
