import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Nav, NavItem } from 'reactstrap'
import { ROUTES } from '../../contants/routeContants'

export default function Footer() {
  const location = useLocation();

  return (
    <Container className="mb-4">
      <Nav className="m-auto flex-md-row justify-content-center" navbar>
        <NavItem active={location.pathname.includes(ROUTES.CONCERNING_REGDOLLS) ? true : false}>
          <Link to={ROUTES.CONCERNING_REGDOLLS}>Concerning ragdolls</Link>
        </NavItem>
        <NavItem active={location.pathname.includes(ROUTES.KITTEN_FOR_ADOPTION) ? true : false}>
          <Link to={ROUTES.KITTEN_FOR_ADOPTION}>Kittens for adoption</Link>
        </NavItem>
        <NavItem active={location.pathname.includes(ROUTES.PHOTO_GALLERY) ? true : false}>
          <Link to={ROUTES.PHOTO_GALLERY}>Photo gallery</Link>
        </NavItem>
        <NavItem active={location.pathname.includes(ROUTES.CONTACT_US) ? true : false}>
          <Link to={ROUTES.CONTACT_US}>Contact us</Link>
        </NavItem>
      </Nav>
      <div className="copy-right-content">
        <p className="mb-0">Copyright © 2023 Sweet Afton Cats</p>
      </div>
    </Container>
  )
}
