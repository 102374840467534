import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap'
import Logo from '../../assets/images/logo.png'
import { ROUTES } from '../../contants/routeContants'

export default function Header() {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Container>
      <Navbar expand="md">
          <Link to="/" className="navbar-brand">
            <img src={Logo} alt="" />
          </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="m-auto" navbar>
            <NavItem active={location.pathname.includes(ROUTES.CONCERNING_REGDOLLS) ? true : false}>
              <Link to={ROUTES.CONCERNING_REGDOLLS}>Concerning ragdolls</Link>
            </NavItem>
            <NavItem active={location.pathname.includes(ROUTES.KITTEN_FOR_ADOPTION) ? true : false}>
              <Link to={ROUTES.KITTEN_FOR_ADOPTION}>Kittens for adoption</Link>
            </NavItem>
            <NavItem active={location.pathname.includes(ROUTES.PHOTO_GALLERY) ? true : false}>
              <Link to={ROUTES.PHOTO_GALLERY}>Photo gallery</Link>
            </NavItem>
            <NavItem active={location.pathname.includes(ROUTES.CONTACT_US) ? true : false}>
              <Link to={ROUTES.CONTACT_US}>Contact us</Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </Container>
  )
}
