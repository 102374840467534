import { Col, Container, Row } from "reactstrap"
import illhandGalleryPage from '../../assets/images/ill_hands_gallery_page.png'
import illhand2GalleryPage from '../../assets/images/ill_hands2_gallery_page.png'
import illhand3GalleryPage from '../../assets/images/ill_hands3_gallery_page.png'
import Contactus from "../../components/Contactus"
import ImageGallery from "../../components/ImageGallery/ImageGallery"

const PhotoGallery = () => {

    const importAll = (r) => {
        return r.keys().map(r);
    }
      
    const siresAndQueensImages = importAll(require.context('../../../public/galleryImages/sires_and_queens/', false, /\.(png|jpg|JPG|PNG|JPEG|jpeg?g|svg)$/));
    const familyPics = importAll(require.context('../../../public/galleryImages/family_pics/', false, /\.(png|jpg|JPG|PNG|JPEG|jpeg?g|svg)$/));
    const sweetAftonsImages = importAll(require.context('../../../public/galleryImages/sweet_aftons_hall/', false, /\.(png|jpe|JPG|PNG|JPEG|jpeg?g|svg)$/));

    return (
        <div className="photo-gallery-wrapper">
            <Container>
                <section className="section-container">
                    <div className="body-content ms-0">
                        <div className="d-flex justify-content-center mb-5">
                            <h2>Sires & Queens</h2>
                        </div>
                        <ImageGallery imagesToShow={siresAndQueensImages} itemsPerPage={4} />
                    </div>   
                </section>

                <section className="section-container">
                    <div className="body-content ms-0">
                        <div className="d-flex justify-content-center mb-5">
                            <h2>Sweet Afton’s Hall of Fame: Royalty Through the Years</h2>
                        </div>
                        <ImageGallery imagesToShow={sweetAftonsImages} itemsPerPage={4} />
                    </div>   
                </section>

                <section className="section-container">
                    <div className="body-content ms-0">
                        <div className="d-flex justify-content-center mb-5">
                            <h2>Sweet Afton Family Pics and Quips</h2>
                        </div>
                        <ImageGallery imagesToShow={familyPics} itemsPerPage={2} />
                    </div>   
                </section>

                <section className="section-container">
                    <Row>
                        <Col md={6}>
                            <div className="h-100">
                                <img className="img-fluid h-100" src={illhandGalleryPage} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="body-content ms-0">
                                <p>One of our Sweet Afton boys was adopted by a neighbor, Mo, and our cats have fun exploring and playing tag while we ladies laugh at the kitties and catch up on the neighborhood news during our play dates! Gio loves to stay close while Mo is curled up with a good book. She laughed, “He acts like he owns the coffee table…and my newspapers!” My response: “Well, of course… he owns everything! He’s just permitting you to exist in his little universe. You should feel honored!”</p>
                                <h5 className="d-flex align-items-center">
                                    <span>&ldquo;</span> In ancient times cats were worshipped as gods; they have not forgotten this.
                                </h5>
                                <h6>Terry Pratchett</h6>
                                <p>We gifted one of our retired queens to our daughter and her family. They chose Porsche, who is getting along great with their children and other pets. They were able to joke with their friends, “So Liv’s parents gave us a Porsche for Christmas, and man does that baby purr!”</p>
                            </div>    
                        </Col>
                    </Row>   
                </section>

                <section className="section-container">
                    <Row>
                        <Col md={6}>
                            <div className="body-content ms-0">
                                <p>One lovely family had contacted us about adopting a Ragdoll for their elderly grandmother, who had been recently widowed, had lost several lifelong friends, and was struggling with health issues. She had become very discouraged and didn’t want to get out of bed, much less enjoy social activities or even spend time with her family. We received a beautiful message from them that since Grandma was introduced to her Ragdoll kitten her family had gifted to her, she now looks forward to getting up every day to feed, care for, and love on her precious kitty, and show him off to her friends and family. She has reengaged in life, is laughing, socializing, and is even sleeping better.</p>
                                <p>One lovely family had contacted us about adopting a Ragdoll for their elderly grandmother, who had been recently widowed, had lost several lifelong friends, and was struggling with health issues. She had become very discouraged and didn’t want to get out of bed, much less enjoy social activities or even spend time with her family. We received a beautiful message from them that since Grandma was introduced to her Ragdoll kitten her family had gifted to her, she now looks forward to getting up every day to feed, care for, and love on her precious kitty, and show him off to her friends and family. She has reengaged in life, is laughing, socializing, and is even sleeping better.</p>
                                <h5 className="d-flex align-items-center">
                                    <span className="gallery-quotes">&ldquo;</span> Hello Dan and Lynne, The &quot;Hello Kitty&quot; party was such fun. We had 14 to celebrate Rory&apos;s arrival. We played kitty games and everything was pink. Rory is doing so well. Thank you so much for socializing her like you did. She&apos;s wonderful!! You can see with the picture of her and my doggies, that they are all adjusting just fine. Blessings to you both and good luck with the kitty business!
                                </h5>
                                <h6>Sincerely, Dottie</h6>
                                <p>These are just a few of the many beautiful stories of Sweet Afton Ragdolls and their families that we have received over the years!</p>
                            </div>    
                        </Col>
                        <Col md={6}className="px-4">
                            <div className="text-start px-4 position-relative h-100">
                                <img className="img-fluid" src={illhand2GalleryPage} />
                                <img className="img-fluid gallery-group-image" src={illhand3GalleryPage} />
                            </div>
                        </Col>
                    </Row>   
                </section>
            </Container> 

            <Contactus />
        </div>    
    )
}

export default PhotoGallery