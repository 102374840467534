import { useState } from 'react';
import ReactPaginate from 'react-paginate'
import backwardArrowIcon from '../../assets/images/backwardArrow.svg'
import forwardArrowIcon from '../../assets/images/forwardArrow.svg'
import { Col, Row } from 'reactstrap';

const ItemsDisplay = ({ currentItems, itemsPerPage }) => {
    return (
      <>
        <Row>
            {currentItems &&
            currentItems.map((item, index) => (
                <Col className="image-container mb-2" xs={24/itemsPerPage} sm={12/itemsPerPage} key={index}>
                    <img className="w-100" height="400" src={item} />
                </Col>
            ))}
        </Row>    
      </>
    );
}
  
const ImageGallery = (props) => {
    const { itemsPerPage, imagesToShow } = props;
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = imagesToShow.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(imagesToShow.length / itemsPerPage);
  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % imagesToShow.length;
      setItemOffset(newOffset);
    };

    const ItemNextLabel = () => {
        return (
            <div className="pagination-label">
                <img src={forwardArrowIcon} />
            </div>
        )
    }

    const ItemPrevLabel = () => {
        return (
            <div className="pagination-label">
                <img src={backwardArrowIcon} />
            </div>
        )
    }
  
    return (
      <>
        <ItemsDisplay currentItems={currentItems} itemsPerPage={itemsPerPage} />
        <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
                nextLabel={<ItemNextLabel />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={0}
                pageCount={pageCount}
                previousLabel={<ItemPrevLabel />}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </div>    
      </>
    );
  }

export default ImageGallery