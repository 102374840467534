import { HashRouter , Route, Routes } from 'react-router-dom';

import './App.scss';
import './assets/Style/main.scss';
import { ROUTES } from './contants/routeContants';
import LandingPage from './pages/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import ConcerningRagdolls from './pages/ConcerningRagdolls';
import ContactUsPage from './pages/ContactUsPage';
import KittenForAdoption from './pages/Kitten';
import PhotoGallery from './pages/PhotoGallery';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <HashRouter >
        <Header />
        <Routes>
          <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
          <Route path={ROUTES.CONCERNING_REGDOLLS} element={<ConcerningRagdolls />} />
          <Route path={ROUTES.PHOTO_GALLERY} element={<PhotoGallery />} />
          <Route path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
          <Route path={ROUTES.KITTEN_FOR_ADOPTION} element={<KittenForAdoption />} />
        </Routes>
        <Footer />
      </HashRouter >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
