import { Button, Container, Col, Row } from 'reactstrap'
import Banner from '../../assets/images/cat.png'
import BtnIcon from '../../assets/images/star.svg'
import AbtImage from '../../assets/images/abot.png'
import Contactus from '../../components/Contactus'
import RagDilImage1 from '../../assets/images/ill_hands_1.png'
import RagDilImage2 from '../../assets/images/ill_hands.png'

const LandingPage = () => {
  return (
    <>
      <Container>
        <section className="section-container">
          <div className="banner-section position-relative">
            <div className="banner-image">
              <img src={Banner} alt="" className="w-100" />
            </div>
            <div className="banner-content position-absolute">
              <h1>Sweet Afton Cats</h1>
              <p>
                Welcome! Located in Colorado Springs, Sweet Afton Cats is a small Ragdoll cattery
                owned by Dan and Lynne Woodhull that began with two purebred Ragdoll kittens of
                champion lineage in the spring of 2004. We are registered with The International Cat
                Association (TICA).
              </p>
              <Button color="primary" className="d-flex justify-content-center align-items-center">
                Contact us <img src={BtnIcon} alt="" className="ms-2" />
              </Button>
            </div>
          </div>
        </section>
        <section className="section-container">
          <Row>
            <Col md="5">
              <div className="img-container mb-4 mb-md-0">
                <img src={AbtImage} alt="" className="w-100" />
              </div>
            </Col>
            <Col md="7">
              <div className="body-content">
                <h2>About us</h2>
                <div className="content-div">
                  <p>
                    We both had grown up with a variety of cats and other family pets, which were
                    our friends and “confidants” during our youth and into adulthood. Our children
                    developed the same love for animals, especially our cats. In our home, a
                    “three-cat nap” (curling up in your favorite napping spot along with three cats)
                    is considered a luxury and an honor!
                  </p>
                  <h5 className='d-flex align-items-center'>
                    <span>&ldquo;</span> You cannot look at a sleeping cat and feel tense.
                  </h5>
                  <h6>Jane Pauley</h6>
                </div>
                <div className="content-div">
                  <p>
                    After our children started college, we thought this would be the perfect time to
                    establish our own cattery. We attended a few local cat shows to talk with
                    cattery owners and learn more about the various popular breeds, and we
                    absolutely fell in love with Ragdolls!
                  </p>
                  <h5>
                    <span>&ldquo;</span> There are two means of refuge from the miseries of life:
                    music and cats.
                  </h5>
                  <h6>Albert Schweitzer</h6>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="section-container">
          <Row>
            <Col md="7">
              <div className="body-content ms-0 me-md-5">
                <h2>About our Ragdolls</h2>
                <div className="content-div">
                  <p>
                    Our Ragdoll Sires, Queens, and pets are a special part of our family, receiving
                    lots of love and attention, and they have daily interaction with us, each other,
                    and our Cocker Spaniel, Fletcher (who has a very special job in helping us take
                    care of the “baby kitties”). Our precious fur-babies share our king-size bed
                    every night, truly enjoying close contact with their people. Ragdoll cats are
                    very gentle, trusting creatures and would not know how to defend themselves if
                    confronted by an aggressive animal or other danger; therefore, as a safety
                    precaution they must be kept indoors, unless they are with a person on a secure
                    halter and leash.
                  </p>
                  <p>
                    Our kittens’ favorite games are pouncing on the creature (hand or foot) under
                    the blanket, playing fetch (yes, fetch!) with a crinkly paper wad or small
                    kitten toy, and “bathtub soccer” with a ping pong ball! As the kittens mature
                    into young cats, their playfulness tones down a bit, and they develop a quiet,
                    stately carriage and bestow upon us humans nearly constant pleasant
                    companionship.
                  </p>
                  <h5>
                    <span>&ldquo;</span> You cannot look at a sleeping cat and feel tense.
                  </h5>
                  <h6>Jane Pauley</h6>
                </div>
                <div className="content-div">
                  <p>
                    Our regal Raggies follow us around the house, sit with us while watching TV or
                    doing computer work, “help” unload groceries, supervise meal preparation and
                    house cleaning, and they love to strut about, showing off their beautiful selves
                    in front of guests. They are ears-over-paws in love with their young people.
                    They enjoy basking in the sun and watching the birds and bunnies from cozy
                    window seats and cat “trees,” and delight in the fresh breezy air on the deck
                    (while supervised). Jules and Lacey wait at the storm door for Fletcher and me
                    to come back from our walks, and there is always a crowd waiting for us to come
                    home after outings.
                  </p>
                  <h5>
                    <span>&ldquo;</span> There are two means of refuge from the miseries of life:
                    music and cats.
                  </h5>
                  <h6>Albert Schweitzer</h6>
                  <p>
                    With the combination of breeders in Sweet Afton’s cattery, we should
                    (theoretically and genetically) be able to produce a well-rounded variety of
                    patterns (bicolor, mitted, colorpoint, and lynx) of the traditional blue and
                    seal colorings. We do have an occasional surprise, such as Thrax, a gorgeous
                    Flame mitted lynx.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="img-container about-ragdol-overlay-images">
                <img src={RagDilImage1} alt="" className="w-100" />
                <img src={RagDilImage2} alt="" className="w-100" />
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <Contactus />
    </>
  )
}

export default LandingPage
